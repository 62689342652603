/**
 * @desc Generates OG tags object from Dato SEO object.
 * @param object Dato SEO object
 * @param string locale, defaulting to "en"
 * @return object
 */
export const createMeta = (seo: any, lang = 'en') => {
  if (!seo) {
    return {
      description: '',
      lang,
      title: '',
    };
  }
  const tags = {
    description: seo.description ? seo.description : '',
    image: null,
    lang,
    title: seo.title ? seo.title : '',
  };

  if (seo.image) {
    tags.image = seo.image;
    // tags.image.width = 310;
    // tags.image.height = 310;
  }
  return tags;
};

/**
 * @desc Generates OG tags object
 * @param string title
 * @param string description
 * @param string image url
 * @param string locale, defaulting to "en"
 * @return object
 */
export const createMetaManually = ({
  title,
  description,
  imageUrl,
  lang = 'en',
}: any) => {
  const tags = {
    description: description ? description : '',
    image: { url: null },
    lang,
    title: title ? title : '',
  };

  if (imageUrl) {
    tags.image = {
      url: imageUrl,
      // width: 310,
      // height: 310
    };
  }

  return tags;
};
